import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom';

const Header = () => {

     return(
        <Fragment>
            <div className="container-fluid bg-dark px-0">
                        <div className="row gx-0">
                            <div className="col-lg-3 bg-dark d-none d-lg-block">
                                <NavLink to="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
                                    <h1 className="m-0 text-primary text-uppercase">Costels</h1>
                                </NavLink>
                            </div>
                            <div className="col-lg-9">
                                <div className="row gx-0 bg-white d-none d-lg-flex">
                                    <div className="col-lg-7 px-5 text-start">
                                        <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                                            <i className="fa fa-envelope text-primary me-2"></i>
                                            <p className="mb-0">info@costels.com</p>
                                        </div>
                                        <div className="h-100 d-inline-flex align-items-center py-2">
                                            <i className="fa fa-phone-alt text-primary me-2"></i>
                                            <p className="mb-0">+91 92345 67899</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 px-5 text-end">
                                        <div className="d-inline-flex align-items-center py-2">
                                            <NavLink className="me-3" to=""><i className="fab fa-facebook-f"></i></NavLink>
                                            <NavLink className="me-3" to=""><i className="fab fa-twitter"></i></NavLink>
                                            <NavLink className="me-3" to=""><i className="fab fa-linkedin-in"></i></NavLink>
                                            <NavLink className="me-3" to=""><i className="fab fa-instagram"></i></NavLink>
                                            <NavLink className="" to=""><i className="fab fa-youtube"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
                                    <NavLink to="index.html" className="navbar-brand d-block d-lg-none">
                                        <h1 className="m-0 text-primary text-uppercase">Costels</h1>
                                    </NavLink>
                                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                        <div className="navbar-nav mr-auto py-0">
                                            <NavLink to="/" className={'nav-item nav-link'}>Home</NavLink>
                                            <NavLink to="/about" className="nav-item nav-link">About</NavLink>
                                            <NavLink to="/services" className="nav-item nav-link">Services</NavLink>
                                            <NavLink to="/room" className="nav-item nav-link">Rooms</NavLink>
                                            <NavLink to="/contact" className="nav-item nav-link">Contact</NavLink>
                                        </div>
                                        <NavLink to="/room" className="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block">Book A Room<i className="fa fa-arrow-right ms-3"></i></NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>
</div>
        </Fragment>
     )
}

export default Header;

