import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const Booking = () => {

     return(
        <Fragment>
            <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="bg-white shadow" style={{ padding: '35px' }}>
                            <div className="row g-2">
                                <div className="col-md-10">
                                    <div className="row g-2">
                                        <div className="col-md-4">
                                            <select className="form-select">
                                                <option selected>Select Your Hostel</option>
                                                <option value="goa">Goa</option>
                                                <option value="arugam_bay">Arugam Bay</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="date" id="date1" data-target-input="nearest">
                                                <input type="text" className="form-control datetimepicker-input"
                                                    placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="date" id="date2" data-target-input="nearest">
                                                <input type="text" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker"/>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-3">
                                            <select className="form-select">
                                                <option selected>Adult</option>
                                                <option value="1">Adult 1</option>
                                                <option value="2">Adult 2</option>
                                                <option value="3">Adult 3</option>
                                            </select>
                                        </div>*/}
                                        {/*<div className="col-md-3">
                                            <select className="form-select">
                                                <option selected>Child</option>
                                                <option value="1">Child 1</option>
                                                <option value="2">Child 2</option>
                                                <option value="3">Child 3</option>
                                            </select>
                                        </div>*/}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-primary w-100">Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
     )
}
export default Booking;