import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Team from '../components/Team';
import Newsletter from '../components/Newsletter';
import Booking from '../components/Booking';

function About(){
    return(
        <Fragment>
             <div className="container-xxl bg-white p-0">

                <Header />

                <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: 'url(assets/img/carousel-1.jpg)' }} >
                    <div className="container-fluid page-header-inner py-5">
                        <div className="container text-center pb-5">
                            <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center text-uppercase">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <Booking />

                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
                                <h1 className="mb-4">Welcome to <span className="text-primary text-uppercase">Costels</span></h1>
                                <p className="mb-4">Costels is a backpacker-friendly hostel brand with three prime locations in Sri Lanka: Arugam Bay, Mirissa, and Weligama. Each property is designed to cater to budget travelers looking for vibrant social spaces, affordable accommodations, and easy access to popular surfing spots and beach attractions. Costels aims to provide a comfortable, community-oriented stay, making it ideal for backpackers exploring the scenic coastal towns of Sri Lanka.</p>
                                <div className="row g-3 pb-4">
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                                        <div className="border rounded p-1">
                                            <div className="border rounded text-center p-4">
                                                <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                                                <h2 className="mb-1" data-toggle="counter-up">40</h2>
                                                <p className="mb-0">Rooms</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                        <div className="border rounded p-1">
                                            <div className="border rounded text-center p-4">
                                                <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                                                <h2 className="mb-1" data-toggle="counter-up">15</h2>
                                                <p className="mb-0">Staffs</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                        <div className="border rounded p-1">
                                            <div className="border rounded text-center p-4">
                                                <i className="fa fa-users fa-2x text-primary mb-2"></i>
                                                <h2 className="mb-1" data-toggle="counter-up">350</h2>
                                                <p className="mb-0">Clients</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link className="btn btn-primary py-3 px-5 mt-2" to="">Explore More</Link>
                            </div>
                            <div className="col-lg-6">
                                <div className="row g-3">
                                    <div className="col-6 text-end">
                                        <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="assets/img/about-1.jpg" style={{ marginTop: '25%' }} />
                                    </div>
                                    <div className="col-6 text-start">
                                        <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="assets/img/about-2.jpg" />
                                    </div>
                                    <div className="col-6 text-end">
                                        <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="assets/img/about-3.jpg" />
                                    </div>
                                    <div className="col-6 text-start">
                                        <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="assets/img/about-4.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               <Team />

                <Newsletter />

                <Footer />

                <Link to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></Link>
            </div>
        </Fragment>
    )
}
export default About;