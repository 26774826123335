import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

const Service = () => {
    
     return(
        <Fragment>
            <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
                            <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-hotel fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Beds & Rooms</h5>
                                    <p className="text-body mb-0">Our hostel features a variety of comfortable beds and rooms, including private deluxe rooms and shared accommodations.</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-utensils fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Food & Restaurant</h5>
                                    <p className="text-body mb-0">At Costels, we pride ourselves on offering a delightful dining experience in our on-site restaurant. Local Sri Lankan cuisine.</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-spa fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Spa & Fitness</h5>
                                    <p className="text-body mb-0">At Costels, we offer a rejuvenating spa and fitness experience to help our guests unwind and stay active during their stay.</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-swimmer fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Sports & Gaming</h5>
                                    <p className="text-body mb-0">At Costels, we provide a vibrant sports and gaming atmosphere for our guests to enjoy. Whether you're a sports enthusiast or just looking to have fun</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-glass-cheers fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Event & Party</h5>
                                    <p className="text-body mb-0">We host vibrant events and parties, providing the perfect backdrop for celebrations, social gatherings, and unforgettable memories.</p>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                                <Link className="service-item rounded" to="">
                                    <div className="service-icon bg-transparent border rounded p-1">
                                        <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                            <i className="fa fa-dumbbell fa-2x text-primary"></i>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">GYM & Yoga</h5>
                                    <p className="text-body mb-0">We offer a well-equipped gym and yoga classes, encouraging guests to maintain their fitness routine while enjoying a relaxing vacation.</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
     )
}
export default Service;