import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {

     return(
        <Fragment>
            <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s">
                    <div className="container pb-5">
                        <div className="row g-5">
                            <div className="col-md-6 col-lg-4">
                                <div className="bg-primary rounded p-4">
                                    <Link to="index.html"><h1 className="text-white text-uppercase mb-3">Costels</h1></Link>
                                    <p className="text-white mb-0">
                                    Costels is a backpacker-friendly hostel brand with three prime locations in Sri Lanka: Arugam Bay, Mirissa, and Weligama.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h6 className="section-title text-start text-primary text-uppercase mb-4">Contact</h6>
                                <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Main Road, Arugam Bay, Sri Lanka</p>
                                <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+94 345 67890</p>
                                <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@costels.com</p>
                                <div className="d-flex pt-2">
                                    <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-twitter"></i></Link>
                                    <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-facebook-f"></i></Link>
                                    <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-youtube"></i></Link>
                                    <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-linkedin-in"></i></Link>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                                <div className="row gy-5 g-4">
                                    <div className="col-md-6">
                                        <h6 className="section-title text-start text-primary text-uppercase mb-4">Company</h6>
                                        <Link className="btn btn-link" to="/about">About Us</Link>
                                        <Link className="btn btn-link" to="/contact">Contact Us</Link>
                                        <Link className="btn btn-link" to="">Privacy Policy</Link>
                                        <Link className="btn btn-link" to="">Terms & Condition</Link>
                                        <Link className="btn btn-link" to="">Support</Link>
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="section-title text-start text-primary text-uppercase mb-4">Services</h6>
                                        <Link className="btn btn-link" to="/services">Food & Restaurant</Link>
                                        <Link className="btn btn-link" to="/services">Spa & Fitness</Link>
                                        <Link className="btn btn-link" to="/services">Sports & Gaming</Link>
                                        <Link className="btn btn-link" to="/services">Event & Party</Link>
                                        <Link className="btn btn-link" to="/services">GYM & Yoga</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="copyright">
                            <div className="row">
                                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                    &copy; <Link className="border-bottom" to="#">Costels</Link>, All Right Reserved. 
                                    Designed By <Link className="border-bottom" to="https://htmlcodex.com">Costels</Link>
                                </div>
                                <div className="col-md-6 text-center text-md-end">
                                    <div className="footer-menu">
                                        <Link to="">Home</Link>
                                        <Link to="">Cookies</Link>
                                        <Link to="">Help</Link>
                                        <Link to="">FQAs</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
     )
}

export default Footer;