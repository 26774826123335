import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const Team = () => {

     return(
        <Fragment>
           <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title text-center text-primary text-uppercase">Our Team</h6>
                            <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Staffs</span></h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="rounded shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img className="img-fluid" src="assets/img/team-1.jpg" alt="" />
                                        <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 mt-3">
                                        <h5 className="fw-bold mb-0">Venu Gopal</h5>
                                        <small>Founder</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="rounded shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img className="img-fluid" src="assets/img/team-2.jpg" alt="" />
                                        <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 mt-3">
                                        <h5 className="fw-bold mb-0">Suresh</h5>
                                        <small>Chef</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="rounded shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img className="img-fluid" src="assets/img/team-3.jpg" alt="" />
                                        <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 mt-3">
                                        <h5 className="fw-bold mb-0">Vimal</h5>
                                        <small>Advisor</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                                <div className="rounded shadow overflow-hidden">
                                    <div className="position-relative">
                                        <img className="img-fluid" src="assets/img/team-4.jpg" alt="" />
                                        <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-facebook-f"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-twitter"></i></Link>
                                            <Link className="btn btn-square btn-primary mx-1" to=""><i className="fab fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 mt-3">
                                        <h5 className="fw-bold mb-0">Roopesh</h5>
                                        <small>Manager</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
     )
}
export default Team;
